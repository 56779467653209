import { graphql, useStaticQuery } from "gatsby"
import React from 'react';
import Grid from "../../../common/Grid"
import NonStretchedImage from "../../../common/NonStretchedImage"
import styles from './styles.module.scss';

const appImagesQuery = graphql`
     query {
    image1: file(relativePath: { eq: "cases/otovo/otovo-app/otovo-app-1.webp" }) {
      ...fluidImage
    }
    image2: file(relativePath: { eq: "cases/otovo/otovo-app/otovo-app-2.webp" }) {
      ...fluidImage
    }
    image3: file(relativePath: { eq: "cases/otovo/otovo-app/otovo-app-3.webp" }) {
      ...fluidImage
    }
    image4: file(relativePath: { eq: "cases/otovo/otovo-app/otovo-app-4.webp" }) {
      ...fluidImage
    }
    image5: file(relativePath: { eq: "cases/otovo/otovo-app/otovo-app-5.webp" }) {
      ...fluidImage
    }
    image6: file(relativePath: { eq: "cases/otovo/otovo-app/otovo-app-6.webp" }) {
      ...fluidImage
    }
  }
  `;

const AppImage = (): JSX.Element =>  {
  const data = useStaticQuery(appImagesQuery);

  return (
    <Grid>
        <NonStretchedImage className={styles.image} {...data.image1.childImageSharp} />
        <NonStretchedImage className={styles.image} {...data.image2.childImageSharp} />
        <NonStretchedImage className={styles.image} {...data.image3.childImageSharp} />
        <NonStretchedImage className={styles.image} {...data.image4.childImageSharp} />
        <NonStretchedImage className={styles.image} {...data.image5.childImageSharp} />
        <NonStretchedImage className={styles.image} {...data.image6.childImageSharp} />
    </Grid>
  )
}

export default AppImage;
