import classnames from'classnames';
import React from 'react';
import Grid from "../../../common/Grid"
import styles from './styles.module.scss';

interface Props {
  text: string;
  margin?: boolean;
}

const Watermark = ({text, margin}: Props): JSX.Element =>  {
  return (
    <Grid>
      <div className={classnames(styles.container, margin ? styles.margin : null)}>
        <p>{text}</p>
      </div>
    </Grid>
  )
}

export default Watermark;
