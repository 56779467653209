import React from 'react';
// @ts-ignore
import OtovoGif from "../../../../images/cases/otovo/otovo.gif"
import Grid from "../../../common/Grid"
import styles from './styles.module.scss';


const Gif = (): JSX.Element =>  {
  return (
    <Grid className={styles.container}>
        <img src={OtovoGif} alt="Otovo app gif" />
    </Grid>
  )
}

export default Gif;
