import React from 'react';
import Otovo from "../components/cases/Otovo"

const Page = (): JSX.Element => (
  <>
    <Otovo />
  </>
);

export default Page;
