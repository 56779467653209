import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from "../../../layouts/Default"
import NonStretchedImage from "../../common/NonStretchedImage"
import Nav from "../common/Nav"
import TextBlock from "../common/TextBlock"
import TopSection from "../common/TopSection"
import AppImage from './AppImage';
import Sketch from './Sketch';
import styles from './styles.module.scss';
import Watermark from "./Watermark"

const imagesQuery = graphql`
     query {
    image1: file(relativePath: { eq: "cases/otovo/otovo1.webp" }) {
      ...fluidImage
    }
  }
  `;

const Otovo = (): JSX.Element => {
  const data = useStaticQuery(imagesQuery);
  const deliveries = ['Konseptutvikling', 'Grafisk design', 'Prototyping', 'Animasjon', 'Illustrasjon'];

  return (
    <Layout className={styles.container}>
      <TopSection project={"Ny merkevare og identitet"} deliveries={deliveries}>
        <p>
          <b>Otovo er Nordens</b> ledende totalleverandør av solceller for privat- og bedriftskunder.
          De er i dag en av Nordens mest fremgangsrike oppstartsselskaper - og opererer i Europa og Sør-Amerika.
        </p>
      </TopSection>
      <NonStretchedImage {...data.image1.childImageSharp} />
      <TextBlock header={"Om prosjektet"}>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac const tur ac, vestibulum at eros.
          Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nulla vitae elit libero, a pharetra augue.
        </p>
        <p>
          Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nulla vitae elit libero, a pharetra augue.
          Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Nulla vitae elit libero, a pharetra augue.
          Etiam porta sem malesda magna mollis euismod.
        </p>
      </TextBlock>
      <Watermark text={"Otovo app"}/>
      <AppImage/>
      <Watermark text={"Sustainable"}/>
      <Sketch/>
      <Nav/>
    </Layout>
  )
};

export default Otovo;

