import { graphql, useStaticQuery } from "gatsby"
import React from 'react';
import Grid from "../../../common/Grid"
import NonStretchedImage from "../../../common/NonStretchedImage"
import Gif from "../Gif"
import styles from './styles.module.scss';

// TODO: make all the images to have the same format
const sketchQuery = graphql`
     query {
    image1: file(relativePath: { eq: "cases/otovo/sketch/1.png" }) {
      ...fluidImage
    }
    image2: file(relativePath: { eq: "cases/otovo/sketch/2.webp" }) {
      ...fluidImage
    }
    image3: file(relativePath: { eq: "cases/otovo/sketch/3.png" }) {
      ...fluidImage
    }
  }
  `;

const Sketch = (): JSX.Element =>  {
  const data = useStaticQuery(sketchQuery);

  return (
    <Grid className={styles.container}>
      <Gif/>
      <NonStretchedImage className={styles.image} {...data.image1.childImageSharp} />
      <NonStretchedImage className={styles.image} {...data.image2.childImageSharp} />
      <NonStretchedImage className={styles.image} {...data.image3.childImageSharp} />
    </Grid>
  )
}

export default Sketch;
